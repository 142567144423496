/* reCAPTCHA badge */
.grecaptcha-badge {
    z-index: 9999;
}

/* COLORS */
.blue {
    background-color: #3b5998 !important;
    color: #b5c8ef !important;
}

.blue a {
    color: #fff !important;
}

.navbar.blue .navbar-nav > li.show > a.dropdown-toggle {
    background-color: #536ea7;
}

.navbar.blue .navbar-nav > li.show > .dropdown-menu > a {
    color: #333 !important;
}

.red {
    background-color: #981309 !important;
    color: #b5c8ef !important;
}

.red a {
    color: #fff !important;
}

.navbar-default.red .navbar-nav > .open > a {
    background-color: #c71509;
}

.navbar-default.red .dropdown-menu > li > a {
    color: #333 !important;
}

.front .navbar {
    margin-bottom: 0px;
}

/* General overrides
-------------------------------------------------- */
.row {
    margin-top: 1rem;
}


/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Padding on top because of the fixed nav bar */
    padding-top: 3.5rem;
    /* Margin bottom by footer height */
    margin-bottom: 132px !important;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 112px;
}

/* Wizard styles
-------------------------------------------------- */
